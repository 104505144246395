@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-blue-600: #4656ca;

  --foreground-rgb: 38, 38, 38;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --swiper-pagination-color: var(--color-blue-600);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 5px;
  --swiper-pagination-bullet-height: 5px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 12px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

/* @media (prefers-color-scheme: dark) */
.dark {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
}

body {
  font-family: var(--font-montserrat);

  @apply antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-bold my-4;
}

h1 > span,
h2 > span,
h3 > span,
h4 > span,
h5 > span,
h6 > span {
  @apply text-blue-700;
}

h1 {
  @apply text-3xl sm:text-5xl sm:leading-snug;
}

h2 {
  @apply text-4xl leading-snug;
}

h3 {
  @apply text-3xl leading-snug;
}

h4 {
  @apply text-2xl leading-snug;
}

h5 {
  @apply text-xl leading-snug;
}

h6 {
  @apply text-xl italic leading-snug;
}

.lead {
  @apply text-lg leading-relaxed italic my-4;
}

p {
  @apply leading-relaxed my-4;
}

.form input[type="text"],
.form input[type="address"],
.form input[type="url"],
.form input[type="password"],
.form input[type="email"],
.form input[type="search"],
.form input[type="tel"],
.form textarea,
.form select {
  @apply !w-[calc(100%-25px)] !max-w-[calc(100%-25px)] !min-w-0 !h-auto !px-4 !py-2 !box-border;
}

.form input[type="button"],
.form input[type="submit"] {
  @apply min-w-[180px] !cursor-pointer !text-white !bg-blue-600 !ring-blue-600 hover:!bg-blue-700 hover:!ring-blue-700 !h-9 !px-4 !py-2 !inline-flex !items-center !justify-center !rounded !ring-2 !font-semibold !transition-colors focus-visible:!outline-none focus-visible:!ring-2 focus-visible:!ring-blue-600 focus-visible:!ring-offset-2 disabled:!pointer-events-none disabled:!opacity-50;
}

@media (max-width: 480px) {
  .form input[type="button"],
  .form input[type="submit"] {
    @apply w-full;
  }
}

.form textarea {
  @apply !min-h-[100px];
}

.form iframe {
  @apply !w-full !bg-transparent !border-0;
}

.form table {
  @apply !w-full !max-w-full !bg-transparent;
}

.form table tr td:first-child {
  @apply text-left whitespace-normal w-2/5;
}

@media (max-width: 768px) {
  .form table tr {
    @apply !block !mb-[20px];
  }

  .form table tr td {
    @apply !block !w-full !p-0;
  }

  .form table tr td input {
    @apply !m-0;
  }
}



@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}



@layer base {
  * {
    @apply border-border outline-ring/50;
  }
  body {
    @apply bg-background text-foreground;
  }
}
